import React from "react";
import LinkButton from "./subcomponents/LinkButton";
import coopLinks from "../data/coop-menu";

import Meta from "./subcomponents/Meta";
// import { Link } from "react-router-dom";

export default function CoopLights () {

  Meta(`Co-op Lights and Postitions - bfmhfansite.com`, `Learn about what those lights and shirt colors mean in your co-op`); 
  
  return (
    <React.Fragment>
      
      {/* ********** Navbar (Quick Links) ************ */}
      <nav className="menu-content building-menu">
        {coopLinks.map(link => {
          return(<LinkButton 
            key={link.key}
            name={link.name}
            link={link.link}
          />)
        })}
      </nav>
    
      <h2 style = {{"margin": "20px"}}>Co-op Lights and Positions</h2>
      <img className = "natural-image" src = "/images/co-op/Lights.png" alt = "co-op lights"></img>
      <div className = "village-lights">
        <br/>
        <h4><img className = "coop-lights" src = "/images/co-op/red-coat.png" alt = "Red Coat"></img>Leader (aka Red Coat)</h4>
        <p>The leader can perform every operation inside the co-op.  They can:</p>
        <ul>
          <li>change the description and minimum join level</li>
          <li>promote, demote or remove any player in the co-op</li>
          <li>send player invites</li>
          <li>start and cancel projects</li>
          <li>edit the note pad</li>
          <li>donate to the village, purchase upgrades and activate the tractor</li>
          <li>view and use the Cooperative leadership chat</li>
        </ul>
        <p>Only the leader can promote someone to leader, after which they will become a deputy.  If the leader leaves the co-op, the highest ranking member will become the leader.</p>
        <br/>
        <h4><img className = "coop-lights" src = "/images/co-op/yellow-coat.png" alt = "Yellow Coat"></img>Deputy (aka Yellow Coat)</h4>
        <p>The deputies can perform every operation that the leader can (including promoting and demoting other deputies) except for removing or demoting the leader.  They can not boot a fellow deputy, but they can demote them and then boot them.  Silly.</p>
        <br/>
        <h4><img className = "coop-lights" src = "/images/co-op/blue-coat.png" alt = "Blue Coat"></img>Member (aka Blue Coat)</h4>
        <p>Members can contribute to projects and donate to the village.  They cannot see the Cooperative leadership chat.</p>
        <br/>

        <h4><img className = "coop-lights" src = "/images/co-op/red.png" alt = "red light"></img>Co-op Lights - Bad Information</h4>
        <p>Big Farm provided an explaination of what the lights mean on their Support page that was incorrect.  They finally updated their documentation to reflect what the players know actually happens.  There may still be some bad information circulating out there.</p>
        <br/>

        <h4><img className = "coop-lights" src = "/images/co-op/on-green.png" alt = "On Light"></img>Bright Green Light</h4>
        <p>This means that the member is online or has been online in the last 14 mins.  Sometimes, if you devices goes to sleep or you open another app, the connection can stay active in the background.  Bottom line is that you can't truly trust the green light to tell if a person is physically present.  You know how it is...  the lights are on, but nobody is home.</p>
        <br/>
        <h4><img className = "coop-lights" src = "/images/co-op/green.png" alt = "green light"></img>Green Light</h4>
        <p>This means that the member has been online in the last 24 hours or day.</p>
        <br/>
        <h4><img className = "coop-lights" src = "/images/co-op/yellow.png" alt = "yellow light"></img>Yellow Light</h4>
        <p>This means that the member has not been online for 24 to 48 hours (1 to 2 days).  This one also seems to be glitch some times and will go yellow after 12 hours.</p>
        <br/>
        <h4><img className = "coop-lights" src = "/images/co-op/orange.png" alt = "orange light"></img>Orange Light</h4>
        <p>This means that the member has not been online for the last 48 to 168 hours (3 to 7 days).</p>
        <br/>
        <h4><img className = "coop-lights" src = "/images/co-op/red.png" alt = "red light"></img>Red Light</h4>
        <p>This means that the member has not been online in the last week (7 days).  If a leader goes red, they will be automatically removed from the leadership position after 60 days (as of 2022), made a deputy and the highest ranked member "should" become the new leader.  I say should because there have been many glitches and bugs with this system, but it seems to be ironed out.  If the someone else, like the newest member or lowest member becomes the leader, contact support and they will correct it.  After 28 days, a player is also removed from the rankings and will no longer appear when searched for.</p>
        <br/>
        
      </div>  

    </React.Fragment>
  );
}
