import React, { useState, useEffect }from 'react';
import LinkButton from "./subcomponents/LinkButton";
import menuLinks from "../data/main-menu";

import axios from 'axios';

//import "./Navbar.css";
  
export default function Navbar () {

  const [links, setLinks] = useState(menuLinks)

  useEffect(()=>{                    // Grabs the envents
    axios.get('/api/seasonal-event/current')
      .then(res => {
        // console.log("Current Seasonal Event thumb from database:", res.data.thumb);
        
        const updatedLink = {key: 8002,
          name: res.data.thumb,
          link: "/seasonal",
          alt: "seasonal event"
        }
        // console.log("new event: ", updatedLink)
        
        setLinks(links.map(link => link.link === "/seasonal" ? updatedLink : link));
        // console.log("links: ",links)
      });
  },[links]);  

  return(
    <nav className="navbar"> 
      <div className="menu-content">
        {links.map(link => {
          return(<LinkButton 
            key={link.key}
            name={link.name}
            link={link.link}
            sub={link.sub}
          />)
        })}
      </div>
    </nav>
  );
}