import React from "react";
import LinkButton from "./subcomponents/LinkButton";
import buildingLinks from "../data/building-menu";
import Meta from "./subcomponents/Meta";
import {Link} from 'react-router-dom';

// ************** Main Function *****************

export default function Land () {

  Meta(`Land Expansion Costs - bfmhfansite.com`, `Big Farm Mobile Harvest Fansite list of land expansions and cost.`);
  return (
    <section className = "building-tables">
      <nav className="menu-content building-menu">
        {buildingLinks.map(link => {
          return(<LinkButton 
            key={link.key}
            name={link.name}
            link={link.link}
          />)
        })}
      </nav>
      
      <img className = "natural-image" src = "/images/fixed/land-expansion-one.png" alt = "first land expansion"></img>
      <img className = "natural-image" src = "/images/fixed/land-expansion-two.png" alt = "second land expansion"></img>
      <div className = "blog-post">
        <p>Land vs Expansion is a complex dynamic to explore.  Is it better to mature a farm, or expand a farm?  The game has a dynamic flow to the land expansions, and I'm of the opinion that it is better to work with that flow and expand before upgrading.</p>
        <p>Now I don't mean all level 1 buildings.  As buildings upgrade the cost increases much faster than the performance and there are sweet spots that you can get value out of a building without using money that can be better spent else where.  <Link to = '/guides/most-efficient-building-levels'>The most efficient building post</Link> covers this.</p>
        <p>Expansions can be broken into 3 phases:</p>
        <ul style = {{textAlign: "left"}}>
          <li>Expansion 10 to approximately 32 - you wait for materials to be able to expand, and often you are level locked.  This is where it is key to send contracts.  The ship and train become a distraction.</li>
          <li>Expansion 32 to 42 - you have the materials, but money is the problem. Upgrades get expensive and you have to wait and save to expand. It is possible to have the materials needed for a few expansions saved up.  The ship and train (fully loaded, not part full) can help, but the materials are still very important for the next phase.  Do not neglect your material bonus upgrades or slow down collecting materials.</li>
          <li>Expansion 43 and above - you can earn the money 3-4 times faster than the materials, which means while you are waiting for the materials, you will have $200 million that needs a home and is perfect for all of the upgrades you put off.</li>
        </ul>
        <p>The task book will want you to do the impossible - expand and upgrade - and most people will be drawn towards upgrading a $3,000,000 building instead of saving $40,000,000 to expand.  There are times in this game, especially in the middle phase, where you will need to just stop upgrading for a week or month to save cash.</p>
      </div>
      
      
    </section>)

}


