import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// import "./Blog.css";

import Loading from './subcomponents/Loading';
import Oops from './subcomponents/Oops';
import InnerPage from './subcomponents/InnerPage';

export default function StockPage () {

  const {pageName} = useParams() ;
  
  //if in the list of guides, redirect
  // console.log("pageName", pageName);

  const [page, setPage] = useState({
    title: "Loading...",
    html: "Please Wait"
  });

  useEffect(()=>{
    axios.get('/api/blog/' + pageName)
    .then(res => {
      setPage(res.data[0]);
    });
      
  },[pageName]);

  
  
  if (page === undefined) {
    // no blog was found
    // REPLACE THIS
    return <Oops text = "That page doesn't seem to exist...  Check the link and try again.  I'll take you back to the home page" link="/"/>

    // console.log("Blog does not exist");
    // return <Redirect to="/blog" />
  } else if (page.title === "Loading...") {
    return <Loading text = "Loading Page..." />

  } else {
    // console.log(page)
    return <InnerPage blog = {page} />
    
  }
}