import React, { useEffect } from "react";
// import axios from 'axios';
// import {Link} from 'react-router-dom';

// import LinkButton from "./subcomponents/LinkButton";
// import buildingLinks from "../data/building-menu";

// import Loading from "./subcomponents/Loading";
import Meta from "./subcomponents/Meta";
// import Oops from "./subcomponents/Oops";
// import Land from "./Land";

function numberWithCommas(x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
  return x;
}

function HeaderRow () {
  const stages = [1,2,3,4,5,6]
  return <tr>
    <th>Player Level</th>
    {stages.map((stage, i) => { 
      return <th key = {i}><img  src = {"/images/cafe/threshold-" + stage +".png"} alt="Cafe Threshold"></img></th>
      
    })}
  </tr>
}

function TableRow (props){
  // console.log(props)
  const {level, index} = props
  const baseCash = (index+1) * 10000
  const baseHappiness = 30 + (index)*20
  return <tr>
    <td>{level}<img className = "building-level" src = {"/images/buildings/xp.png"} alt="player xp"></img></td>
    <td>
      {numberWithCommas(baseHappiness)}<img className = "building-level" src = {"/images/buildings/chap.png"} alt="cafe happiness"></img><br />
      {numberWithCommas(baseCash)}<img className = "building-level" src = {"/images/buildings/cash.png"} alt="cash"></img><br />
    </td>
    <td>
      {numberWithCommas(baseHappiness*4)}<img className = "building-level" src = {"/images/buildings/chap.png"} alt="cafe happiness"></img><br />
      {numberWithCommas(index+1)}<img className = "building-level" src = {"/images/buildings/keys.png"} alt="keys"></img><br />
    </td>
    <td>
      {numberWithCommas(baseHappiness*8)}<img className = "building-level" src = {"/images/buildings/chap.png"} alt="cafe happiness"></img><br />
      {numberWithCommas(baseCash*2)}<img className = "building-level" src = {"/images/buildings/cash.png"} alt="cash"></img><br />
    </td>
    <td>
      {numberWithCommas(baseHappiness*12)}<img className = "building-level" src = {"/images/buildings/chap.png"} alt="cafe happiness"></img><br />
      {numberWithCommas(baseCash*5)}<img className = "building-level" src = {"/images/buildings/cash.png"} alt="cash"></img><br />
    </td>
    <td>
      {numberWithCommas(baseHappiness*18)}<img className = "building-level" src = {"/images/buildings/chap.png"} alt="cafe happiness"></img><br />
      {index <= 2 ? 
        <span>{numberWithCommas((index+1)*2)}<img className = "building-level" src = {"/images/buildings/keys.png"} alt="keys"></img></span> : 
        <span>{Math.floor(numberWithCommas((index+1)*2.5))}<img className = "building-level" src = {"/images/buildings/keys.png"} alt="keys"></img></span>}
    </td>
    <td>
      {numberWithCommas(baseHappiness*24)}<img className = "building-level" src = {"/images/buildings/chap.png"} alt="cafe happiness"></img><br />
      {index <= 2 ? 
        <span>{numberWithCommas((index+1)*5)}<img className = "building-level" src = {"/images/buildings/keys.png"} alt="keys"></img><br /></span>:
        <span>{numberWithCommas((index-2)*5)}<img className = "building-level" src = {"/images/buildings/mallet.png"} alt="mallet"></img><br /></span>
      }
    </td>  
  </tr>
}

// ************** Main Function *****************

export default function Cafe () {

  useEffect(()=>{
    const viewportmeta = document.querySelector('meta[name=viewport]');
    viewportmeta.setAttribute('content', "width=device-width, initial-scale=1.0");
  },[])


// **************************************************************************************************************************************  
  const levels = ["40-49", "50-59", "60-66", "67-79", "80-89", "90-99", "100-124", "125-149", "150-174", "175-199", "200-249", "250-299", "300+"]
    
  Meta(`Cafe Rewards - bfmhfansite.com`, `The reward stages by player level for Big Farm Mobile Harvest's Cafe.`);
  return (
  <div className = "building-tables" >
      {/* ********** Navbar (Quick Links) ************ */}
      {/* <nav className="menu-content building-menu">
        
        {buildingLinks.map(link => {
          return(<LinkButton 
            key={link.key}
            name={link.name}
            link={link.link}
          />)
        })}
        
      </nav> */}
      {/* ********* Table Name ********** */}

    

      <div style = {{"display": "block", "Postition": "relative"}} >
        <div className = "scroller-header" >
          <img className="building-large" src={"../images/buildings/cafe.png"} alt=""></img>
          <h2 className = "building-name">Cafe Rewards</h2>
          <p className = "watermark">bfmhfansite.com</p> 
        </div>
        
        {/* ********** Table ************** */}
        <div className = "building-table-overflow">
          <p1>Cafe rewards are based only on player level, as shown in the table below, and cap at level 300.</p1><br/>

          <p1>Each recipe has 7 levels, each giving 10% more customer statisfaction.  The level of the recipe is locked to the level of the cafe.</p1><br/>
          <p1>Upgrading the Cafe only allows for higher level recipes to be made.  The return on investment for level 6 and 7 are debatable.</p1>

          <table id= "buildings" className = "building-table">
            <thead>
                <HeaderRow />
            </thead>  
            <tbody className = "notranslate">
              {levels.map((level, i)=> <TableRow key = {i} index = {i} level = {level} />)}
            </tbody>
          </table>
          
        </div>
      </div>  
    </div>
  )

}

