// import React from "react";

export default function Meta (title, description) {
  document.title = title;
  document.querySelector('meta[name=description]').setAttribute('content', description);
  return null
}

// <meta property="og:title" content="Big Farm Mobile Harvest Fansite"/>
// <meta property="og:type" content="website"/>
// <meta property="og:image" content="%PUBLIC_URL%/images/ogImage/ogJames.png"/>
// <meta property="og:url" content="http://www.bfmhfansite.com"/>
// <meta property="og:description" content="Big Farm Mobile Harvest Fansite.  Where fans come for knowledge and resources."/> 

// useEffect(() => {
//   Meta("BFMH Fansite", "Big Farm Mobile Harvest Fansite.  Where great fans come for knowledge and resources.")
// }, []);

// Meta(`${building.name}s - bfmhfansite.com`, `All of the information you need for Big Farm Mobile Harvest's ${building.name}s.  Upgrade, yeild, best levels, etc. `);