import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";


export default function Oops (props) {

  const [pause, setPause] = useState(false);
  
  useEffect(()=>{
    const timer = setTimeout(()=>setPause(true), 7000);
    // return clearTimeout;
    return () => clearTimeout(timer);
  }, [])


  return(<div>
    <br/>
    <h3>Oops!  Something went wrong...</h3>
    <img src = "/images/misc/oops.png" alt = "Loading"></img>
    <h4>{props.text}</h4>
    {pause && <Navigate to = {props.link}/>}
  </div>)
}
