import React from "react";
import Meta from "./subcomponents/Meta";

// ************** Main Function *****************

export default function Fishing () {

  Meta(`Fishing - bfmhfansite.com`, `Big Farm Mobile Harvest Fansite - all about fishing.  Fish types, fillets awarded, rod improvements, etc`);
  return (
    <section className = "blog-post">
      <h3>Fishing</h3>
      <p><img className = "pimage psm prt" src = "/images/buildings/fish-cafe.png" alt = "Fish cafe" />In the future I will include a good look at the fishing improvements and tips to get the best return for your investments. </p>
      <p>A quick overview of improvements is that the top row will speed up how fast you fish, often leading to a better fishing experience. The attractant shortens the time needed to catch the fish, the line decreases the number of hits (pulls the fish further), and the reel makes it so you don't have to be as accurate.  Upgrading these takes fishing from 50 seconds to 30 seconds to 20 seconds.</p>
      <p>The second row is the scale, cooler and landing net.  The scale gives you more experience with each catch.  The only thing experience does is unlock upgrades and this may be a problem with the rod in the very early stages, but once the conches needed to upgrade the rod increases, level is pointless.  The cooler increases the cash that you get for selling fish and the returns are absolutely pathetic for the costs.  Don't take this very far at all.  The landing net increases the number of conches you get for special catches (1% chance).  How far you take this needs to depend on your farm income.  Making fish fingers once a week will compensate for not upgrading this.</p>
      <p>The bottom row is the big one.  The rod increases the fish's weight and the number of fillets you catch.  If you are going to spend lots of time and/or good lures fishing, make sure you get a good catch when you can.  Multiple pans are also a huge benefit.  Even if you can only fish for one pan, keeping white or green in the other two pans help with upgrades, dogbones, seaweed humus during seasonal events and seaweed fertilizer.</p>
      <br/>
      <p>Did you know... the trigger for fishing (and everything else in this game) is on the release, not the touch... you may find it much easier to hold down on the screen and then release when the bright green in under the needle.</p>
      <p>The new site offers a lot more flexibility, so I would like to finally implement the fishing rod calculator. It will most likely happen when I make the fish weight table.</p>
      <p>For now, I can tell you that a fishing rod upgrade of 7 will guarantee 2 fillet Mahi Mahi, and level 16 will give you a chance at 3 fillet Mahi Mahi.</p>
      <p><img className = "pimage pum plt" src = "/images/buildings/mallet.png" alt = "Mallet" /> The main source of mallets are from fishing.  Start early and save purple and red fillets for later in the game (level 67) to make mallets.</p>
      <p><img className = "pimage pum prt" src = "/images/co-op/filletr.png" alt = "Red Fish Fillet" />So how do you catch more Mahi Mahi? Unfortunately, you just have to fish. You will average one Mahi or Swordfish every 40 casts with a green uncommon lure. You will average one of those every 10 casts with a super lure. If you don't catch something you want, sell it and try again.</p>
      <p>Personally, if I have time (about once a day), I will fish to fill my pans with something good. The rest of the day I stock green and white fillets. Green fillets have great value for seasonal events.</p>
      <br/>
      
      <img className = "pimage" src = "/images/fixed/fishing.png" alt = "fish weigh table"></img>
      {/* <img className = "pimage" src = "../images/fixed/land-expansion-two.png" alt = "second land expansion"></img> */}
    </section>)

}
