import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import cookiePolicyDate from '../../data/privacy-date';
  
export default function Cookie () {


  const [cookie, setCookie] = useState (true);

  useEffect(()=>{
    // localStorage.removeItem("_CookieNotification")
    const dateAcknowledged = localStorage.getItem("_CookieNotification");
    // console.log ("from local storage", dateAcknowledged)

        
    if (!dateAcknowledged) {                                  // no cookie found, show policy
      setCookie(false)
      // console.log("No CookNote found")
      return
    }                              
    
    // console.log("ack", dateAcknowledged, "policy", cookiePolicyDate)

    if (new Date(dateAcknowledged) > new Date(cookiePolicyDate)) {
      
      return
    } else {
      setCookie(false)
      // console.log("Privacy cookie is invalid")

    }

  },[])


  function setAgreement() {
    localStorage.setItem('_CookieNotification', new Date() );
    setCookie(true);
  }

  if (cookie) {
    return null
  } else {
    return <div className = "cookie-notification">
      <button className="button cookie-button" onClick = {setAgreement}>
        <img className = "button-icon" src={"/images/menu/ok.png"} alt="check mark" />
      </button>
      <p className = "cookie-text" >This site uses cookies.  You can read more about our policy <Link to = "/privacy">here</Link>.</p>
    </div>
  }
 
}


// localStorage.setItem("lastname", "Smith");
// localStorage.getItem("lastname");