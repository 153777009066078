import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InfoButton from './subcomponents/InfoButton';
import parseBlog from './subcomponents/ParseBlog';
import { Link } from 'react-router-dom';
// import {Link} from 'react-router-dom';

 
export default function News (props) {

  const {archive} = props  
  const [news, setNews] = useState();

  useEffect (()=>{
		axios.get('/api/news/')
      .then(res => {
        setNews(res.data);  
      });
  },[])
  
  

  if (news === undefined) {
    return <React.Fragment>
      <br/>
      <h4>Fetching the news.  Give me a second or two...</h4>
    </React.Fragment> 
  } else {
    // console.log(archive);
    let filteredNews = [];
    if (archive) {
      filteredNews = news;
    } else {
      filteredNews = news.filter(news => !news.archive)
    }
    // console.log("Pre", filteredNews)
    return <section className = "news">
      <h2><img className = "pum" src="/images/menu/news.png" alt = "News"></img> News</h2>
      {filteredNews.map((newsItem, i)=>{
        return <NewsCard 
          key = {i}
          link = {newsItem.link}
          title = {newsItem.title}
          html = {newsItem.html}
          date = {newsItem.date}
        />
      })}
      {!archive &&<h4><img className = "pum" src="/images/menu/news.png" alt = "News"></img> Archived news <Link to = "/news">here</Link></h4>}
    </section>
  }
}

function NewsCard (props) {
  const [isSummary, setIsSummary] = useState(true);
  function createMarkup(html) { return {__html: html}};
  
  const maxLength = 200;
  const newsLength = props.html.length;
  // console.log (newsLength);

  return(<div className = "card-preview">
    {props.link !== undefined && <InfoButton link = {props.link}/>}
    <h3 className = "title">{props.title}</h3>
    <p className="news-date">{new Date(props.date).toDateString()}</p>
    <div className = "excerpt" dangerouslySetInnerHTML={createMarkup(parseBlog((isSummary &&(newsLength > maxLength))? props.html.slice(0,maxLength)+"... " : props.html))}/>
    {newsLength > maxLength && <Link to = "#" onClick = {() => setIsSummary(prev => !prev)}>{isSummary? "read more": "read less"}</Link>}
    <div style = {{"clear": "both"}} />
  </div>)
}
