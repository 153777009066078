const dreamLinks = [
  {
    key: 201,
    name: "dream-farm",
    link: "/dream"
  }
  ,
  {
    key: 202,
    name: "dream-questbook",
    link: "/dream/quests"
  },
  {
    key: 203,
    name: "dream-buildings",
    link: "/dream/buildings/windmill"
  },
  {
    key: 204,
    name: "dream-deco",
    link: "/dream/buildings/flowerbed"
  }, 
  {
    key: 205,
    name: "dream-other",
    link: "/dream/buildings/farmhouse"
  },
  {
    key: 206,
    name: "dream-land",
    link: "/dream/buildings/land"
  }  
];

export const dreamQuestLegend = [
  { 
    pic: "xp", 
    blurb: "This is the level the quest is unlocked.  Most quests need the previous quest of the same type completed to unlock the next step at the shown level.  If a level is not listed, it is unlocked after the previous quest is completed."
  },{ 
    pic: "actions/build", 
    blurb: "This means you need to build or place the building.  The number shows the total needed."
  },{ 
    pic: "actions/upgrade", 
    blurb: "This means you need to upgade a building to the level shown."
  },{ 
    pic: "actions/expand", 
    blurb: "This means buy the next farm expansion."
  },{ 
    pic: "actions/begin", 
    blurb: "This means you need to start the production of something."
  },{ 
    pic: "egg", 
    blurb: "Any item with a number and product means collect that many resources."
  },{ 
    pic: "actions/buy", 
    blurb: "This means you need to buy an item."
  },{ 
    pic: "coming-soon", 
    blurb: "This symbol means I don't know, or I don't have the proper picture yet."
  },{ 
    blurb: "Tasks that don't offer any mascot coins, or aren't needed to unlock future quests with mascot coins, are removed from the table."
  }
]

export default dreamLinks;

