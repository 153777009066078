import React from "react";
import { Link } from "react-router-dom";

export default function OldLink (props) {
  return(<div>
    <br/>
    <h4>hmm...  you are using an old post link...</h4>
    <img src = "../images/misc/loading.png" alt = "Loading"></img>
    <div className = "blurb">
      <p>
        You found this page using an old link found somewhere on the internet.  Some of the posts have found a new home here under <Link to ="/blog">blogs</Link> or <Link to ="/guides">guides</Link>.  Some posts are still being rewritten and will come back soon.  Be sure to update your links.
      </p>
      <h4>The most common old posts:</h4>
      <ul style = {{"textAlign": "left"}}>
        <li><Link to = "/happiness">Are you looking for happiness?</Link></li>
        <li>Nectar and Honey coming soon</li>
        <li><Link to = "/gold">Are you looking for Acquiring Gold? </Link></li>
      </ul>
      
      </div>

  </div>)
}
