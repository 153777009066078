import React, { useState, useEffect} from "react";
import axios from 'axios';
// import "./Calendar.css";
// import InfoButton from "./subcomponents/InfoButton";


// Takes the time remaining and returns a string of days/hours or hours/mins with pural correction
// function remainingString(targetDate, currentDate) {

// 	function plural (item, text) { 
// 		return item + " " + text + (item !== 1 ? "s" : "")
// 	}

// 	let timeRemaining = targetDate - currentDate;
// 	let days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
// 	let hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
// 	let minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));

// 	// console.log("days: " + days + " hours: " + hours + " minutes: " + minutes);
	
// 	return (days === 0 ? plural(hours, "hour") + ", " + plural(minutes, "min"):plural(days, "day") + ", " + plural(hours, "hour"));
// }

// Main calendar box function
export default function ComingUp (props) {
	
	const [calendarEvents, setCalendarEvents] = useState([]);
	const [dayOffset, setDayOffset] = useState(0);
	

	let todayDate = new Date(); 						//Get the current date
	todayDate.setDate(todayDate.getDate() + dayOffset);	 		//modifier for testing
	console.log(todayDate);

	const day = todayDate.getDay();

	// console.log("day: ", day, " date: ", todayDate)

	if (day >= 2) {
		todayDate.setDate(todayDate.getDate()- day + 2)
	} else {
		todayDate.setDate(todayDate.getDate()- day - 5)
	}

	// console.log("Adjusted date: ", todayDate)

	let oneWeekDate = new Date(); 
	let twoWeekDate = new Date();

	oneWeekDate.setDate(todayDate.getDate() + 7);
	twoWeekDate.setDate(todayDate.getDate() + 14);

	// event.startDate.setDate(event.startDate.getDate() + event.repeat);
	
	function parseISOString(s) {
		let b = s.split(/\D+/);
		return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
	}

	useEffect(()=>{
		//axios.get('http://localhost:5000/calendar/')
    axios.get('/api/calendar/')
      .then(res => {
				res.data.forEach(item => {
					item.startDate = parseISOString(item.startDate);
					item.endDate = parseISOString(item.endDate);
				});
				setCalendarEvents(res.data);
      });
  },[]);


  	
	// takes the events and breaks them into sorted current and future events
	const stillRunning = calendarEvents
	.filter((event)=> event.startDate < todayDate)// grabs events that have already started
	.filter((event)=> event.endDate > todayDate) // and then removes dates that have finished
	.filter((event)=> event.suspend !== true)    // remove suspended events
	.filter((event)=> event.extends)
	.sort((a, b)=>new Date(a.startDate) - new Date(b.startDate)); // and then sorts by ending soonest ending first

 	// console.log ("Still running events", stillRunning);

	const thisWeek = calendarEvents
		.filter((event)=> event.startDate > todayDate)// grabs events that have already started
		.filter((event)=> event.startDate < oneWeekDate ) // and then removes dates that have finished
		.filter((event)=> event.suspend !== true)    // remove suspended events
		.sort((a, b)=>new Date(a.startDate) - new Date(b.startDate)); // and then sorts by ending soonest ending first
	// console.log ("current events", currentEvents);
	
	const nextWeek = calendarEvents
	.filter((event)=> event.startDate > oneWeekDate) // grabs events that are still to run 
	.filter((event)=> event.startDate < twoWeekDate)
	.filter((event)=> event.suspend !== true)
	.sort((a, b)=>new Date(a.startDate) - new Date(b.startDate)) // and sorts by starting the soonest first
	.slice(0, 8); // limit the number of items in the calendar.
// console.log ("future events", futureEvents);

	const handleChangeOffset = (event) => {
		console.log(event.target.value);
		setDayOffset(parseInt(event.target.value));
	};

  
  return(
    // <div className="calender-widget">
	<div className =  "blog-post">
		<p>You have found this page by mistake.  It is still in development for use with Facebook.  Please check back later.</p>
		<input type="number" value={dayOffset} onChange={handleChangeOffset} />
		<br />
		<h1>Coming Up - {todayDate.toDateString()}</h1>
      {/* <h2 className="ce-main-title">Current Events<span className = "watermark">bfmhfansite.com</span></h2> */}
			{/* {currentEvents.map((event, i) =>{
				return (
					<div key = {i} className="calendar-event-box" style={{"borderColor": event.color}}>
						{event.link && <InfoButton link = {event.link} />}
						<img className="calendar-event-thumb" src = {"/images/calendar/" + event.pic + "-thumb.png"} alt={event.name}/>
						<p className = "calendar-event-title">{event.name}</p>
						<p className = "calendar-event-date">{event.end ? event.end : dateToggle ? "Ends " + event.endDate.toDateString() : "Ends in " + remainingString(event.endDate, todayDate)}</p>
					</div>)
				})}  */}
				
			{/* <h2 className="ce-main-title">Upcoming Events<span className = "watermark">bfmhfansite.com</span></h2> */}
			{/* {futureEvents.map(event=>mapFutureElement(event, todayDate))} */}
			{stillRunning.length >0 && (<div>
					
				
				<h2>Special Events</h2>
				{stillRunning.map((event, i)=>{
					return (
						<div key = {i}>
							<p>Ends {event.endDate.toDateString()} - <a href = {event.link}>{event.name}</a></p>
						</div>)
					})} 
				</div>)}
				
			<h2>This Week</h2>
			{thisWeek.map((event, i)=>{
				return (
					<div key = {i}>
						<p>{event.startDate.toDateString()} - <a href = {event.link}>{event.name}</a></p>
					</div>)
				})} 
			<h2>Next Week</h2>
			{nextWeek.map((event, i)=>{
				return (
					<div key = {i}>
						<p>{event.startDate.toDateString()} - <a href = {event.link}>{event.name}</a></p>
					</div>)
				})} 
		</div>
  )
}

