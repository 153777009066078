import React, { useState, useEffect } from "react";
import Oops from "./Oops";

export default function Loading (props) {

  const [error, setError] = useState(false);

  useEffect(()=>{
    const timer = setTimeout(()=>setError(true), 8000);
    return () => clearTimeout(timer);
  }, [])
  
  if (error) {
    return <Oops text = "Something seems to be broken getting the page you were looking for.  Let me take you back to the home page." link="/"/>
  } else {
    return(<div>
      <br/>
      <h4>{props.text}</h4>
      <img src = "/images/misc/loading.png" alt = "Loading"></img>
    </div>)
  }
}
