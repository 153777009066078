const buildingLinks = [
  {
    key: 201,
    name: "buildings",
    link: "/buildings/house",
    alt: "Regular buildings page link"
  },
  {
    key: 202,
    name: "deco",
    link: "/buildings/flowerbed",
    alt: "Decorations page link"
  },
  {
    key: 203,
    name: "special-buildings",
    link: "/buildings/gnome-house",
    alt: "Special buildings page link"
  },
  {
    key: 204,
    name: "special-deco",
    link: "/buildings/tree-of-lanterns",
    alt: "Special Decorations page link"
  },
  {
    key: 205,
    name: "other",
    link: "/buildings/farmhouse",
    alt: "Other buildings page link"
  },
  {
    key: 206,
    name: "land",
    link: "/buildings/land",
    alt: "Land costs page link"
  }
  ,
  {
    key: 207,
    name: "pbuilding",
    link: "/buildings/premium-house",
    alt: "Premium buildings page link"
  }
];


export default buildingLinks;