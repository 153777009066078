import React from 'react';
import {Link} from 'react-router-dom';

// import "../Navbar.css";
  
export default function LinkButton (props) {

  return (
    <div className = "navbar-button">
      <Link to={props.link} >
        <button className="button">
          <img className = "button-icon" src={"/images/menu/"+props.name+".png"} alt={props.name+ " page link"} />
        </button>
      </Link>
      
    </div>
  );
}

