import React from 'react';

export default function Welcome () {
  
  return(
    <section>
      <h3>Welcome</h3>
      <div className = "card-preview">
        <p>This site exists to help you navigate the realities of the game to get the most joy and satisfaction you can out of our community, your effort, investments and time.</p>
        
        <p>You are here because you want tools to help you play and grow.  Whether you tend your small plot or desire to be a mega farm, play for relaxation, personal challenge, socialization and/or to help others, there's something here for you.  People play games for many different reasons, but it all boils down to the satisfaction of fulfilling personal needs.  Helping people fill those needs builds great relationships and co-ops.</p>
        
        <p>Any free game exists to make money for it's developers - subscriptions, packages and ad revenue.  I was curious to know how the game was going to encourage me to spend real money.  I quickly realized that the blue task book was designed to slow down your farm.  Patience and Focus...  I reverse engineered the game and built a farm model to play with.  By largely ignoring the book, understanding how the game will try and trap you and understanding returns on investments, you can find a more efficient way to farm and grow.</p>

        <p>The website is a journey.  It is a labor of love; so much love that I studied full stack web design.  Welcome to version 2!  A site that loads!  There is now a solid foundation in place on which to build, so I will slow build up different areas of this site.  I had to remove the forum and co-op listings to start, many of the blogs will be updated before they are reposted, and the library, market, fishing and adventure farm upgrades are still being built.  I hope to bring the interactive dynamic back to the site in the future.  I am happy that you are here to enjoy the ride with me.</p>
        
        <p>Big thanks to Big Farm for their support in this project.  (We are not officially affiliated, but have a great relationship.) More thanks to the Admins of the European Facebook groups and a good collection of close friends that have helped launch this.  Lastly, thanks to my family for putting up with my farming addiction.</p>

  ​      <p>Check us out on <a href = "https://www.facebook.com/groups/BFMH.FarmersFanPage/">Facebook</a> as well.</p>
      </div>
      
      
    </section>
  );
}