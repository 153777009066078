import React from "react";
import LinkButton from "./subcomponents/LinkButton";
import coopLinks from "../data/coop-menu";

import Meta from "./subcomponents/Meta";
import { Link } from "react-router-dom";

export default function CoopVillage () {

  Meta(`Co-op Village - bfmhfansite.com`, `Details about the co-op village, how it helps your co-op, how to contribute funds and how to not buy an upgrade.  Big Farm Mobile Harvest.`); 
  
  return (
    <React.Fragment>
      
      {/* ********** Navbar (Quick Links) ************ */}
      <nav className="menu-content building-menu">
        {coopLinks.map(link => {
          return(<LinkButton 
            key={link.key}
            name={link.name}
            link={link.link}
          />)
        })}
      </nav>
    
      <h2 style = {{"margin": "20px"}}>Co-op Village</h2>

      <div className = "village-blurb">
        <img src = "/images/co-op/village.png" alt = "co-op village"></img>
        <p> </p>
        <br/>
        <p>When you go to your co-op screen, the last tab takes you to the village.  There are 5 buildings (I'm calling the tractor a "building") around a central park.  Each of these buildings represent an upgrade for your co-op.  The level of the upgrade can be found on a little sign beside the building.  More info on these buildings, upgrade costs and value can be found <Link to = "/co-op/upgrades/funds">here</Link>.  The tractor is special and needs to be activated (10,000 village funds) to have it's bonus apply.</p>
        <br/>
        <p>To purchase village funds - also refered to as donating to the village - click on the little blue plus button beside the available funds at the bottom of the screen.  You will get a pop up (shown) with 2 sliders, one to use cash to purchase village funds and one to use gold to purchase village funds.</p>
        <p>This uses your personal farm cash or gold to contribute funds to the village.</p>
        <p>Funds are purchased in increments of 10,000 farm cash for 100 co-op funds (100 to 1 ratio) or 50 gold for 50 co-op funds.  One of the upgrades is a funds bonus.  Shown is a bonus of +5 - 105 per 10,000 farm cash or 55 per 50 gold.</p>
        <p>Some packages and the gold offers for co-op tournament levels can also contribute funds to the co-op.</p>
        <br/>
        <p>To purchase an upgrade, just click on the building and tap "Buy".  Only the leader and deputies can purchase upgrades or activate the tractor in the village.</p>  
        <p>THERE IS NO CONFIRMATION!</p>
        <p>A lot of co-op tension comes from accidental co-op bonus purchases, especially those with little value.  It is very easy to bump a button when looking at what the upgrade does.  Many co-op upgrades (and wildflower books) have also been purchased by a button mashing Jr. Farmer.</p>
      </div>  

    </React.Fragment>
  );
}


