import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Navigate } from "react-router-dom";
import Loading from "./subcomponents/Loading";

export default function ThemeRedirect () {
  const [isLoad, setIsLoad] = useState(null);

  // console.log("present");
  useEffect(()=>{
    axios.get('/api/calendar/current')
      .then(res => {
        // console.log("Current event search results: ", res.data);
        setIsLoad(res.data.link);
      });
  },[]);  
  
  if (isLoad) {
    // console.log("Data has been fetched.  Redirect to " + isLoad);
    return <Navigate to = {isLoad} />
  } else {
    // console.log("Data isn't loaded yet.  Waiting on search result");
    return <Loading text="Fetching the current theme event" />
  }
}
