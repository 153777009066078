import React from 'react';
import {Link} from 'react-router-dom';

//import "./Header.css";
 
export default function Header (props) {

  return(
    <div className="header">
      <Link to="/">
        <img className="logo logo-fs" src="/images/misc/logo-james-sm.png" alt="Smart Farmer Logo"/>
      </Link>
      <h1 className="header-title">Big Farm Mobile Harvest Fansite</h1>
      <a href="https://goodgamestudios.com/games/goodgame-big-farm-mobile/">
        <img className="logo logo-bf" src="/images/misc/logo-bf-sm.png" alt="Big Farm Mobile Harvest Logo"/>
      </a>
      <br></br>
    </div>   
  );
}


// target="_blank"