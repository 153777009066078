import React from 'react';
// import {Link} from 'react-router-dom';

//import "./Header.css";
import LinkButton from './subcomponents/LinkButton';
 
export default function Footer (props) {

  return(
    <footer className="footer">
      <a href="https://www.facebook.com/groups/BFMH.FarmersFanPage/">
        <img className="logo-fb" src="/images/misc/facebook.png" alt="Big Farm Mobile Harvest Logo"/>
      </a>
      <LinkButton  name = "contact" link = "/contact" /> 
      <p>©bfmhfansite.com {new Date().getFullYear()}</p>
      
    </footer>   
  );
}


