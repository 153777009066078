import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

// import "./Blog.css";
import Meta from './subcomponents/Meta';

export default function Guide () {
  
  const [blogs, setBlogs] = useState([]);
  
  useEffect(()=>{
    Meta("Guides - bfmhfansite.com", "Big Farm Mobile Harvest Fansite Guides.  Getting started, the key to success, basic tips, flowcharts, efficient buildings, and more!")
		axios.get('api/game/')
      .then(res => {
        // console.log("resulting blog search: ", res.data);
        setBlogs(res.data);
   
      });
  },[]);

  function BlogCard (props) {
    return(<div className = "card-preview">
        <h2 className = "title">{props.title}</h2>  
        <img className = "cover-img" src = {props.cover} alt = "Blog Preview" />
        <p className = "excerpt">{props.excerpt}</p>
        <Link  className = "excerpt" to={"/guides/" + props.link}>Read More...</Link>       
    </div>)
  }
  
  return(
    <main id = "blog-main">

      <h3>Big Farm Mobile Harvest Fansite Guides and Key Concepts</h3>
      <p className = "blurb">There are some concepts the "tutorial" never teaches you that are important to your success in the game.  This section of the website focuses on everything you need to know to understand and navigate the game.  More generalized and strategy posts can be found in the <Link to = "/blog">blog</Link> section.</p>
      <br/>
      <section className = "preview">
        {blogs.map((blog, i) => {
          return(<BlogCard
            key = {i}
            title = {blog.title}
            excerpt = {blog.excerpt}
            link = {blog.link}
            cover = {blog.cover}
          />)
        })}
      </section>
        
      <div className = "menu">
      </div>
    </main>    
  );
}