import React from 'react';
import {Link} from 'react-router-dom';

//import "../Navbar.css";
  
export default function InfoButton (props) {

  return (
    <Link to={props.link} >
      <button className="more-info-button">
        <img className = "more-info-button-icon" src={"/images/calendar/info.png"} alt={"More info link"} />
      </button>
    </Link>
  );

}