const mainMenuLinks = [
  // {
  //   key: 100,
  //   name: "home",
  //   link: "/",
  //   alt: "Home page link"
  // },
  {
    key: 200,
    name: "building",
    link: "/buildings/house",
    alt: "Building page menu"
    
  },
    {
    key: 300,
    name: "themes",
    link: "/themes",
    alt: "Events page link"
  },
  {
    key: 400,
    name: "dream-farm",
    link: "/dream",
    alt: "Dream Farm page link"
  },
  {
    key: 700,
    name: "co-op",
    link: "/co-op/projects",
    alt: "Co-op page"
  },
  // {
  //   key: 400,
  //   name: "blog",
  //   link: "/blog",
  //   alt: "Blog page link"
  // },
  {
    key: 500,
    name: "guide",
    link: "/guides",
    alt: "Guides page link"
  },
  // {
  //   key: 800,
  //   name: "fishing",
  //   link: "/fishing",
  //   alt: "Fishing page link"
  // },
  // {
  //   key: 900,
  //   name: "cafe",
  //   link: "/cafe",
  //   alt: "Cafe page link"
  // },
  
  {
    key: 8002,
    name: "coming-soon",
    link: "/seasonal",
    alt: "seasonal event"
  },
  // {
  //   key: 801,
  //   name: "bee",
  //   link: "/bees",
  //   alt: "Bee tournament page link"
  // },
  // {
  //   key: 802,
  //   name: "tree-of-life",
  //   link: "/tree-of-life",
  //   alt: "Tree of life page link"
  // },
  // { 
  //    key: 1000,
  //    name: "news",
  //    link: "/news"
  // }
  
    {
      key: 2000,
      name: "more",
      link: "/menu",
      alt: "Menu page"
    },
];


export default mainMenuLinks;