import React, { useEffect, useState } from "react";
import axios from 'axios';
import {Link} from 'react-router-dom';

import LinkButton from "./subcomponents/LinkButton";

import Loading from "./subcomponents/Loading";
import Meta from "./subcomponents/Meta";

function MenuCard (props) {
  const {link} = props 
  
  return (
    
    <div className = "menu-card">
      
      <LinkButton 
          name={link.pic}
          link={link.link}
        />
      <h5><Link to = {link.link}>{link.name}</Link></h5>
      <p>{link.description}</p>
      
    </div>
  )

}


// ************** Main Function *****************
export default function SiteMenu () {

  useEffect(()=>{
    const viewportmeta = document.querySelector('meta[name=viewport]');
    viewportmeta.setAttribute('content', "width=device-width, initial-scale=1.0");
  },[])

// **************************************************************************************************************************************  
  const [menus, setMenus] = useState();
   
  useEffect(()=>{
    axios.get('/api/menu')
      .then(res => {
        setMenus(res.data[0]);
        
      });
  },[]);  

  if (menus === undefined) {
    return<Loading text = "Loading links..." />
  } 

  Meta(`Menu - bfmhfansite.com`, `All of the important links in one spot.`);
  // const menu = menus[0].data[0];
  
  return (
    <section className = "site-menu">
      <h2>Site Menu</h2>
      {menus.data.map((subMenus, i)=>{return (
        <div className = "submenu" key = {i}>
          {subMenus.visible === true && ( 
            <React.Fragment>
              {subMenus.title !== "None" && <h4>{subMenus.title}</h4>}
              {subMenus.links.map((link, i)=>{return (<MenuCard key = {i} link = {link} />)})}
            </React.Fragment>
          )}
        </div>
        )}
      )}


      {/* }
      {console.log(menu)}
      <h1>Site Map</h1>
      <h2>{menu.title}</h2>
      {menu.links.map((link, i)=>{return (
      <div key = {i}>
        <LinkButton 
            key={i}
            name={link.pic}
            link={link.link}
            
          />
        <p>{link.name}</p>
      </div>
      
      )})}*/}
    </section>) 
    
  

}


