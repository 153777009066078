import React, { useState } from 'react';
import Meta from './Meta';
import parseBlog from './ParseBlog'

export default function InnerPage (props) {
  
  const {blog} = props;
  const [index, setIndex] = useState(0);
  
  function createMarkup(innerHtml) { return {__html: parseBlog(innerHtml)}; }

  Meta(blog.title, blog.excerpt);
  if (typeof blog.html === "string") {
    return(
      <article className = "blog-post">
        <h2 className = "blog-title">{blog.title}</h2>
        <div className = "blog-content" dangerouslySetInnerHTML={createMarkup(blog.html)}/>
      </article>    
    );
  }
  else {
    return(
      <article className = "blog-post">
        <h2 className = "blog-title">{blog.title}</h2>
        <img className = "guide-image" src = {blog.cover} alt = "Blog Preview" />
        <div className = "scroller-header" >
          <button className="button scroller-left" onClick = {index > 0 ? (()=>setIndex(p => p - 1)) : null} >
            <img className = "button-icon" src={"/images/menu/previous.png"} alt="previous entry" />
          </button>
          <p className = "sroller-right">{ index + 1 +"/"+ blog.html.length}</p>
          <button className="button scroller-right" onClick = {(index + 1) < blog.html.length ? (()=>setIndex(p => p + 1)): null}>
            <img className = "button-icon" src={"/images/menu/next.png"} alt="previous entry" />
          </button>
        </div>
        <div className = "card-preview">
          <h2 className = "title">{blog.html[index].title}</h2>  
          <div className = "excerpt" dangerouslySetInnerHTML={createMarkup(blog.html[index].html)}/>
        </div>
      </article>    
    );

  }

}