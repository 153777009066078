const coopLinks = [
  {
    key: 201,
    name: "projects",
    link: "/co-op/projects"
  },
  {
    key: 202,
    name: "village",
    link: "/co-op/village"
  },
  {
    key: 203,
    name: "village-upgrade",
    link: "/co-op/upgrades/funds"
  }, 
  {
    key: 204,
    name: "co-op-lights",
    link: "/co-op/lights"
  } 
];


export default coopLinks;