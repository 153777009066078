import React from "react";
// import LinkButton from "./subcomponents/LinkButton";

import Meta from "./subcomponents/Meta";
// import { Link } from "react-router-dom";

export default function Bees () {

  Meta(`Tessa's Bee Tournament`, `All about the bees: where to get them, why you want them, and what to do with them.`); 
  
  return (
    <React.Fragment>
      <article className = "blog-post">
        <h2><img className = "pimage pum" style={{"position": "relative", "bottom": "5px"}}src = "/images/blog/bee.png" alt = "Honey Bee" /> Tessa's Bee Tournament</h2>
        <img className = "pimage" src = "/images/blog/bee-banner.png" alt = "Bee tournament banner" />
        <br/> <br/>
        <p><img className = "pimage pum plt" src = "/images/blog/bee.png" alt = "Honey Bee" />Tessa’s Bee Tournament is a 3 day mini game or tournament.  It runs every other weekend from Friday morning to Monday morning.</p> 
        <p><img className = "pimage psm prt" src = "/images/blog/bee-beds.png" alt = "Bee Flower Beds" />The game consists of 39 flower beds arranged in a 4 ring bullseye pattern.  12 players (in the same league that enter in succession) are arranged around the outside.  The object of the game is to collect the most Royal Jelly.  You collect Royal Jelly each second from each flower bed that you own (or control).  The closer to the middle the flower bed is, the more Royal Jelly it will give you.  There are also collection bonuses for owning multiple flower beds of the same colour (like owning continents in Risk). </p>
        <p><img className = "pimage psm plt" src = "/images/blog/bee-vsbee.png" alt = "Bee vs Bee" />This is a player vs player style game.  At the start of the game, all flower beds are neutral.  Once they are all controlled, the only way to get more is to take them away from another player, which means they can also be taken from you.  This adds an attack and defensive strategic element.</p>
        <p>The other thing is that the game tends to be self balanced.  You can only attack beds adjacent to ones you own.  The more beds a player controls, the more exposed to attack they become.  Also, the beds closer to the middle are more exposed than the ones on the perimeter.  The better a player does, the harder it becomes to defend.</p>
        <p>To play, you need to collect bees, enter the game, and then select beds and send bees to battle the bees already on the bed.  This will consume energy.</p>
        <p><img className = "pimage psm prt" src = "/images/blog/bee-interface.png" alt = "Tessa's bee garden" />The game’s interface is found in Tessa’s bee garden in the upper corner of the farm map, to the right of the weekly theme event and farm contest tent.  It is only accessible while the tournament is active.</p>
        <p><img className = "pimage pum plt" src = "/images/blog/bee-contract.png" alt = "Bee contract" />Bees are collected on market contracts.  Nails, scrolls and bricks contracts give 5 bees per contract independent of value.  It could be a $100 nail contract or a $50,000 nail contract, and it will give 5 bees regardless.  Boards, ink, tiles and tailored items contracts give 8 bees.  Saws, books and glass contracts give 10 bees.   To get bees, simply send contracts.  Send lots of contracts.  New in 2020, you can also buy bees if you try to send more than you have for 20 gold each.</p>
        <p><img className = "pimage psm prt" src = "/images/blog/bee-participate.png" alt = "Bee Enter Button" />To enter the tournament, there is actually a “participate” button in the bottom right corner that you need to press after you have collected a minimum of 50 bees.  It is not like other tournaments where you enter by collecting the second stage reward.  This means that you can enter quickly, amass a mighty army of bees or completely skip the tournament.  After the tournament, all bees disappear so that everyone will start the same next tournament.</p>
        <p><img className = "pimage pmd plt" src = "/images/blog/bee-send.png" alt = "How to send bees" />To send bees to a bed, click on the bed and you will have a slider you can adjust to pick the number of bees.  It will also give you your odds of winning.  When you confirm, the attacking and defending bees will fight it out, err, a computer algorithm will calculate the results.  Bees will be (randomly) lost in battle; the more evenly matched, the more bees you will lose.  The remaining bees of the victor will take up residence on the bed.</p>
        <p>Bees can also be sent to reinforce a bed in the same way, just without the battle.  Clicking on a bed you own will allow you to transfer bees from your reserve to the bed, but you can not transfer from the bed to your reserve or to another bed.</p>
        <p>There is a 300 bee limit on any battle or flowerbed to even the game out.  That means that once the game is established, any battle of 300 bees against 300 bees will have a 50/50 chance of success.</p>
        <p><img className = "pimage pum prt" src = "/images/blog/bee-energy.png" alt = "Bee energy symbol" />Each time you move bees or attack a bed consumes energy.  The better the bed, the more energy moves will use.  You start with and can have a maximum of 12 energy.  Energy automatically regenerates one point per 15 mins (4 per hour).</p>
        <p><img className = "pimage pum plt" src = "/images/buildings/bee-token.png" alt = "Bee tournament token" />At the end of the tournament, you will receive bee tokens and tournament tokens.  Higher ranked finishes may also receive keys.  The tokens can be redeemed for a mystery chest (there is a small and large) in the chest tab found under the pig in a shopping cart button.</p>
        <p>I will make a game map and better tables after the next tournament.</p>
        <table style={{"margin":"auto"}}>
          <thead>
            <tr>
              <th>Colour</th>
              <th>Jelly</th>
              <th>Energy</th>
              <th>Bonus (+3/s)</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>Red (x1)</td>
              <td>10/s</td>
              <td>6</td>
              <td>n/a</td>
            </tr>
            <tr>
              <td>Yellow (x6)</td>
              <td>5/s</td>
              <td>4</td>
              <td>2 beds</td>
            </tr>
            <tr>
              <td>White (x12)</td>
              <td>3/s</td>
              <td>3</td>
              <td>3 beds</td>
            </tr><tr>
              <td>Purple (x20)</td>
              <td>1/s</td>
              <td>2</td>
              <td>3 beds</td>
            </tr>
          </tbody>
          
        </table>
        

        <br/>
        <h4 style = {{"textAlign": "left"}}>Strategy</h4>
        <p>There are a few different ways to play the bee tournament:</p>
        <ol style = {{"textAlign": "left"}}>
          <li>Don’t.  Just happily keep farming, collect bees and when the tournament ends they will happily disappear.</li>
          <li>Just the rewards.  The bee stage rewards are based on how many bees you send.  Who wins and how many bees you lose depends on random chance and the bee difference.  It is very possible to continually attack your neighbour’s purple bed, 40-50 bees at a time vs their 300 and you will only cost them a few bees.  You can send 300 bees in 6 batches every two and a half hours and only cost your neighbour 10 bees.  You collect the rewards and don’t really hurt anyone else along the way.</li>
          <li>Just play.  Build up a reserve of bees, send them into the fray, take and defend beds that make sense as a side hobby.  If you do well, great, if not, again great.  You don’t pay that much attention to what is happening on the board.  Because you are smart, you don’t box anyone in.  You just play it because it is there and has some rewards.</li>
          <li>Hardcore.  Put on your general’s hat; you are going into battle!  You mean business.  You market hard to amass a mighty bee army.  You have worked out the most nectar you can get with your first 12 energy, you set alarms when you energy has regenerated for the next move, you have analyzed the farms around the board, and you have your strategies.  You mean business and you don’t care how many people cry as your bee army marauds its way around the board.  Anyone who steals one of your beds shall feel your wrath.</li>
        </ol>
        <p>Should you go straight for the middle bed and defend it for all you are worth, holding 600 bees in reserve if anyone should dare attack it, or should you capture 2 yellow and 3 white to maximize your bonuses?</p>
        <p>Do you take many beds with a few bees each and go back to reinforce them later, or take a few beds and reinforce them with 300 bees immediately?</p>
        <p>The options and strategies are endless in this mini game.  Have fun exploring them all.  Sometimes the strategy you use will depend on the other people around the board.  And don’t forget to make some friends while you play this, along with some enemies.</p>
      </article> 

            
    </React.Fragment>
  );
}
