import React, { useEffect } from 'react';
import Calendar from "./Calendar.jsx";
import Meta from './subcomponents/Meta.jsx';
import News from './News.jsx';
import Welcome from './Welcome.jsx';
import { Link } from 'react-router-dom';


export default function Home () {
  
  useEffect(() => {
    Meta("BFMH Fansite", "Big Farm Mobile Harvest Fansite.  Tips, tricks, upcoming events, theme event tasks and rewards, costs, fishing, tournaments, updates, guides, co-operative projects and guides, analysis, and more.  Everything a fan needs.")
  }, []);

  return(<React.Fragment>
    <div className = "home-left">
    <p className = "getting-started">New to Big Farm?  <Link to = "/getting-started">Get started here.</Link></p>
      <Calendar />
    </div>
    <div className = "home-center">
      <News archive = {false} />
      <br/>
      <Welcome />
    </div>
    <div style = {{"clear": "both"}} />
  
  </React.Fragment>);
   
}