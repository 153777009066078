import React, { useEffect, useState } from "react";
import axios from 'axios';
import {useParams} from 'react-router-dom';

import LinkButton from "./subcomponents/LinkButton";
// import buildingLinks from "../data/building-menu";

//import "./Buildings.css";
import Loading from "./subcomponents/Loading";
import Meta from "./subcomponents/Meta";
import Oops from "./subcomponents/Oops";

function numberWithCommas(x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
  return x;
}

function TableRow (props) {
  const {data, header} = props;
  // console.log(props);
  return (<tr>
    {header.map((col, i) => {         // map each item in the header
      //console.log("Data cell", i);
      if (data[col] === 999) {
        data[col] = "?"
      }
      // console.log (data[col], col);
      if (data[col] === undefined) {    // this data type does not exist, return an empty cell
        return <td key = {i}> </td>
      } else if (col === "unlock") {  // the unlock has special formating
        return <td key = {i}> 
          <div className="unlock-container">
            <img className="unlock-xp-icon" src="/images/buildings/xp.png" alt={col}></img>
            <p className="unlock-xp">{data[col]}</p>
          </div>
        </td>
      } else if (typeof data[col] === "object") {
          const items = data[col];
          //console.log(items);
          return <td key = {i}> {
            Object.keys(items).map((key, i) => {
              // console.log(items[key])
              items[key] === 999 && (items[key] = "?");
              return <React.Fragment key = {i} >
                {col === 'bonus' && "+"}{numberWithCommas(items[key])}<img className = "building-level" src = {"/images/buildings/"+ key +".png"} alt={key}></img><br />
              </React.Fragment>
            })
          }</td>
      } else {
        return <td key={i}> {data[col]}<img className = "building-level" src = {"/images/buildings/"+ col +".png"} alt={col}></img> </td>
      }
      

    })}
  </tr>)
}


// ************** Main Function *****************

export default function MarketUpgrades () {

  useEffect(()=>{
    const viewportmeta = document.querySelector('meta[name=viewport]');
    viewportmeta.setAttribute('content', "width=device-width, initial-scale=1.0");
  },[])


// **************************************************************************************************************************************  
  const [marketUpgrades, setMarketUpgrades] = useState();
  
  const {marketUrl} = useParams();
  // console.log(marketUrl);

  let market;
  
  useEffect(()=>{
    axios.get('/api/market/upgrade')
      .then(res => {
        // console.log(res.data);
        setMarketUpgrades(res.data);
      });
  },[]);  

  if (marketUpgrades === undefined) {
    return<Loading text = "Loading market upgrades..." />
  } else {
    market = marketUpgrades.filter((market)=> market.link === marketUrl)[0];   // grab the building from the parameter pass
    if (market === undefined) {
      return <Oops text = "That market upgrade doesn't seem to exist...  I'll take you back to market upgrades" link="/market/upgrades"/>
    }
  }
  
  // let totalUpgrades = 0;
  // let doneUpgrades = 0;

  // marketUpgrades.forEach(upgrade => {
    
  //   upgrade.levels.forEach(level => {
  //     totalUpgrades++; 
  //     if (Object.values(level["cost"])[0] !== 999) doneUpgrades++;     
  //   })
  // });

  // console.log("Completed: " + doneUpgrades + " of " + totalUpgrades + ", "+ Math.round(doneUpgrades/totalUpgrades*100)+ "%")
  

  let headerList = [];
  const header = market.levels[0];
  Object.keys(header).map((key, i) => {
  //  console.log( typeof header[key] === "object" ? true : false );
    headerList.push(key);
    return null;
      
  });

  // let produce;
  // building.levels[0].produce && (produce = Object.keys(building.levels[0].produce)[0]);
    
  //console.log(produce);

  Meta(`${market.name} - bfmhfansite.com`, `Upgrade costs for the ${market.name}.`);
  return (
    
    
    <div className = "building-tables" >
    {/* onKeyPress = {(e)=>console.log(e)}> */}
      {/* ********** Navbar (Quick Links) ************ */}
      {/* <nav className="menu-content building-menu">
        
        {buildingLinks.map(link => {
          return(<LinkButton 
            key={link.key}
            name={link.name}
            link={link.link}
          />)
        })}
        
      </nav> */}
      
      {/* ********* Table Name ********** */}

      <div style = {{"display": "block", "Postition": "relative"}} >
        <div className = "scroller-header" >
          <div className="scroller-left"> <LinkButton key = "0" name = "previous" link = {"/market/upgrades/" + market.previous} /> </div>
          <div className="scroller-right"><LinkButton key = "10000" name = "next" link = {"/market/upgrades/" + market.next} /></div>
          <img className="building-large" src={"/images/market/"+market.link +".png"} alt={market.link}></img>
          <h2 className = "building-name">{market.name}</h2> 
          <p className = "watermark">bfmhfansite.com</p> 
          {/* {produce && <img className="building-produce" src={"/images/buildings/"+ produce +".png"} alt={produce}></img>} */}
        </div>
        
        {/* {varBuildings !== undefined && <div className = "building-variants">
          <h4>Variants</h4>
         
          {varBuildings.map((variant, i)=> 
            <Link key = {i} to= {"/buildings/" + variant.link}>
               {<img className="building-variant" src={"../images/buildings/"+ variant.link +".png"} alt={variant}></img>}
              
            </Link>)}
            
        </div>} */}
        
        {/* ********** Table ************** */}
        <div className = "building-table-overflow">
          <table id= "buildings" className = "building-table">
            <thead>
              <tr>
                {headerList.map((title, i) => {return <th key = {i}>{(title.charAt(0).toUpperCase() + title.slice(1)).replace("-", " ")}</th>})}
              </tr>
            </thead>  
            <tbody className = "notranslate">
              {market.levels.map((level, i) => <TableRow key = {i} data = {level} header = {headerList} />)}
            </tbody>
          </table>
          
        </div>
        
        {/* {building.variants !== undefined && <div className = "building-variants">
          <h4>Variants</h4>
          {building.variants.map((variant, i)=> 
            <p key = {i}><Link to= {"/buildings/" + variant}>
               {<img className="building-variant" src={"../images/buildings/"+ variant +".png"} alt={variant}></img>}
              {buildings.filter((building)=> building.link === variant)[0].name}
            </Link></p>)}
        </div>} */}

              
      </div>  
    </div>
  )

}


// function debounce(fn, ms) {
//   let timer
//   return _ => {
//     clearTimeout(timer)
//     timer = setTimeout(_ => {
//       timer = null
//       fn.apply(this, arguments)
//     }, ms)
//   };
//}