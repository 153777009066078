export default function parseBlog (html) {
  return (html
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;sr<img/g, '<img class = "pimage psm prt"' )
    .replace(/&amp;sl<img/g, '<img class = "pimage psm plt"' )
    .replace(/&amp;mr<img/g, '<img class = "pimage pmd prt"' )
    .replace(/&amp;ml<img/g, '<img class = "pimage pmd plt"')
    .replace(/&amp;tr<img/g, '<img class = "pimage pth prt"')
    .replace(/&amp;tl<img/g, '<img class = "pimage pth plt"')
    .replace(/&amp;ur<img/g, '<img class = "pimage pum prt"')
    .replace(/&amp;ul<img/g, '<img class = "pimage pum plt"')
    .replace(/&amp;na<img/g, '<img class = "pimage"')
  );
}