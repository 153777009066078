import React, { useState, useEffect } from "react";
import axios from 'axios';

import LinkButton from "./subcomponents/LinkButton";
import Loading from "./subcomponents/Loading";
import coopLinks from "../data/coop-menu";

import Meta from "./subcomponents/Meta";
import { Link, useParams } from "react-router-dom";
import Oops from "./subcomponents/Oops";
// import InfoButton from "./subcomponents/InfoButton";

export default function CoopUpgrade ({match}) {

  const {upgradeUrl} = useParams();
  // console.log(upgradeUrl)
  
  const [village, setVillage] = useState();
    
  useEffect(()=>{                    // Grabs the envents
    axios.get('/api/village')
      .then(res => {
        // console.log("Village from database", res.data);
        setVillage(res.data);
        //console.log(res.data);
      });
  },[]);  

  let upgrade; 

  if (village === undefined) {   // data is not loaded yet.
    // console.log("Data is not loaded from database yet, put up loading screen");
    return <Loading text = "Loading upgrades..." />
  } else {
    upgrade = village.filter((upgrade)=> upgrade.link === upgradeUrl)[0];   // grab the building from the parameter pass
    if (upgrade === undefined) {
      return <Oops text = "That upgrade doesn't seem to exist...  I'll take you back to village upgrades" link="/co-op/upgrades/funds/"/>
    }
  }
  
     
  //************************* Master Function Return ******************************/
  // const upgrade = village[index];
  //console.log("Single Upgrade", upgrade)
  Meta(`Co-op Village Upgrades - bfmhfansite.com`, `Details about the co-op village upgrades, how they help your co-op and what they cost.  Big Farm Mobile Harvest.`); 
  
  return (
    <React.Fragment>
      
      {/* ********** Navbar (Quick Links) ************ */}
      <nav className="menu-content building-menu">
        {coopLinks.map(link => {
          return(<LinkButton 
            key={link.key}
            name={link.name}
            link={link.link}
          />)
        })}
      </nav>
    
      <h2 style = {{"margin": "20px"}}>Co-op Village Upgrades</h2>

      <div className = "village-blurb">
        
        <div className = "scroller-header" >
          <div className="scroller-left">
            <LinkButton key = "0" name = "previous" link = {"/co-op/upgrades/" + upgrade.previous} />
          </div>
          <div className="scroller-right">
            <LinkButton key = "1" name = "next" link = {"/co-op/upgrades/" +upgrade.next} />
          </div>
          <h3 className = "village-upgrade-name building-name">{upgrade.name}</h3> 
          <img className="building-produce" src={"/images/co-op/"+ upgrade.link +".png"} alt={upgrade.link}></img>
        </div>

        <section className = "village-table">
          <table className = "building-table">
            <thead>
              <tr>
                <th style = {{"position": "relative"}}><span className = "watermark-vil">bfmhfansite.com</span><span className = 'plus'>+</span><img className = "village-task-icon" src = {"/images/co-op/"+ upgrade.link +".png"} alt= {upgrade.link}></img></th>
                <th></th>
              </tr>
            </thead>
            <tbody className = "notranslate">
                
              {upgrade.levels.map((level, i)=>{
                if (level.cost === 999) {level.cost = "?"}
                return(<tr key = {i}>
                  <td>{level.bonus}</td>
                  <td>{numberWithCommas(level.cost)}<img className = "building-level" src = {"/images/co-op/"+ level.unit +".png"} alt= {level.unit}></img><br /></td>
                </tr>
              )})}
                
            </tbody>
          </table>
        </section>
        <section className = "village-writeup">
          <img className = "village-icon" src = {"/images/co-op/"+ upgrade.link+"-building.png"} alt = {upgrade.link+" building"}></img>
          <p>{upgrade.info.description}</p>
          <h4>Value</h4>
          <p>{upgrade.info.value}</p>

          {upgrade.moreLink && <Link to = {upgrade.moreLink}>{upgrade.moreDesc}</Link>}
        </section>
        <div style = {{"clear": "both"}} />
      </div>  

    </React.Fragment>
  );
}

function numberWithCommas(x) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
  return x;
}

